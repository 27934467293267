<template>
  <div class="multiscreen-grid-cell" @mouseover="showSettings = true" @mouseleave="showSettings = false">
    <div v-if="!cameraInfo " class="multiscreen-grid-cell__container multiscreen-grid-cell__container_empty"
         :class="{'multiscreen-grid-cell__container_alarm': alarmMode}">
      <div v-show="isEditable" class="multiscreen-grid-cell__select-camera" @click="openSelectCameraDialog()">

        <svg class="icon-add">
          <use xlink:href="/img/symbol-defs-v2.svg#icon-add" />
        </svg>
        <p>Добавить камеру</p>
      </div>

      <div v-show="isEditable && showSettings" class="multiscreen-grid-cell-settings" style="color: #3e4347">
        <SmartSwitch v-model="alarmMode" @input="$emit('change-alarm-mode', $event)" />
        <span class="multiscreen-grid-cell-settings__top-item-caption">Тревожная ячейка</span>
      </div>

    </div>
    <div
      v-else
      :class="{'multiscreen-grid-cell__container_alarm': alarmMode}"
      class="multiscreen-grid-cell__container multiscreen-grid-cell__container_active"
    >
      <SmartPlayer
        v-if="cameraInfo.isReadyForLive"
        :archive-token="cameraInfo.tokenDVR"
        :auto-shift-mode="false"
        :available-archive-fragments="availableArchiveFragments"
        :camera-number="cameraInfo.number"
        :domain="cameraInfo.server.domain"
        :has-sound="cameraInfo.hasSound"
        :vendor-name="cameraInfo.server.vendor_name"
        :hot-keys-enabled="false"
        :http-protocol="$store.getters.protocolVideoOverHTTP"
        :initial-low-latency-mode="lowLatencyMode"
        :has-ptz="cameraInfo.isPtz.is_ptz"
        :initial-stream-number="streamNumber"
        :live-token="cameraInfo.tokenLive"
        :stream-count="cameraInfo.streamsCount"
        :use-simple-interface="true"
        :ws-protocol="$store.getters.protocolVideoOverWS"
        class="multiscreen-grid-cell__player"
        @disaster="$emit('need-refresh', cameraInfo.number)"
        :onPTZStart="debouncedStartPTZ"
        :onPTZStop="stopPTZ"
        :onPTZCentralize="centralizePTZ"
      />
      <p v-else>
        Трансляция недоступна. Необходимо изменить камеру.
      </p>

      <div
        v-show="cameraInfo"
        :title="cameraInfo.title"
        class="multiscreen-grid-cell__camera-title"
        v-text="cameraInfo.title"
      />

      <div v-show="cameraInfo && alarmMode" class="multiscreen-grid-cell__alarm">
        <svg class="icon">
          <use xlink:href="/img/symbol-defs-v2.svg#icon-alarm" />
        </svg>
        <span>Тревога</span>
      </div>

      <div v-show="isEditable" class="multiscreen-grid-cell-settings">
        <button
          class="multiscreen-grid-cell-settings__top-item"
          title="Удалить камеру из ячейки"
          type="button"
          @click="deleteCamera()"
        >
          <svg class="icon">
            <use xlink:href="#icon-close" />
          </svg>
        </button>
        <button
          class="multiscreen-grid-cell-settings__top-item"
          title="Изменить камеру в ячейке"
          type="button"
          @click="openSelectCameraDialog()"
        >
          <svg class="icon">
            <use xlink:href="/img/symbol-defs-v2.svg#icon-menu-camera" />
          </svg>
        </button>
        <button
          class="multiscreen-grid-cell-settings__top-item grabbable"
          title="Переместить в другую ячейку"
          type="button"
        >
          <svg class="icon">
            <use xlink:href="#icon-grabbable" />
          </svg>
        </button>
        <div class="multiscreen-grid-cell-settings__top-item">
          <span class="multiscreen-grid-cell-settings__top-item-caption">Тревожная ячейка</span>
          <SmartSwitch v-model="alarmMode" @input="$emit('change-alarm-mode', $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeshFrameDialog from "@/components/meshCameras/MeshFrameDialog.vue";
import {ACTION_LOAD_RECORDING_STATUSES, ACTION_PTZ_CAMERA, CameraInfo} from "@/store/cameras/index.js";
import SmartPlayer from "camsng-frontend-shared/components/smartPlayer/SmartPlayer.vue";
import {TOKEN_TTL, VUEX_CACHE_TTL} from "@/utils/consts.js";
import {whenToUpdateToken} from "@/utils/helpers.js";

/**
 * Компонент для ячейки в сетке мозаики.
 *
 * Предназначен для отображения потока выбранной камеры и интерфейса ее настройки или ее выбора.
 */
export default {
  name: "GridCell",
  components: {
    SmartPlayer,
  },
  props: {
    /**
     * Объект камеры {@link CameraInfo} по которому будет организована трансляция.
     */
    initialCameraInfo: {
      type: CameraInfo,
      default: null,
    },
    /**
     * Начальное положение флага для работы в режиме тревожной ячейки.
     */
    initialAlarmMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Флаг для включения опций редактирования.
     */
    isEditable: {
      type: Boolean,
      default: false,
    },
    /**
     * Поток по умолчанию по которому будет запрошена и воспроизводится трансляция. Зависит от сетки мозаики.
     */
    streamNumber: {
      type: Number,
      default: 2,
    },
    /**
     * Флаг для включения плеера режиме низкой задержки.
     */
    lowLatencyMode: {
      type: Boolean,
      default: true,
    },
    showMediator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cameraInfo: null,
      refreshTimeoutId: null,
      alarmMode: this.initialAlarmMode,
      availableArchiveFragments: [],
      timeoutIdForReceivingRecordingStatus: null,
      ptzInterval: null,
      showSettings: false
    };
  },
  watch: {
    /**
     * Отслеживание изменений в передаваемом параметре объекта камер для корректного обновления содержимого ячейки сетки.
     *
     * @param {CameraInfo} newCameraInfo
     */
    initialCameraInfo(newCameraInfo) {
      this.setupCamera(newCameraInfo);
    },
    /**
     * Отслеживание внешнего флага тревоги для актуального локального значения.
     *
     * @param {Boolean} newAlarmMode
     */
    initialAlarmMode(newAlarmMode) {
      if (newAlarmMode !== this.alarmMode) {
        this.alarmMode = this.initialAlarmMode;
      }
    },
    showMediator(newShowMediator) {
      if (newShowMediator) {
        this.receiveRecordingStatus();
      } else {
        clearTimeout(this.timeoutIdForReceivingRecordingStatus);
        this.availableArchiveFragments = [];
      }
    },
  },
  /**
   * Инициализация начального состояния ячейки.
   */
  created() {
    this.setupCamera(this.initialCameraInfo);
    this.debouncedStartPTZ = _.debounce(this.startPTZ, 0.1);
  },
  /**
   * Очистка таймаута для обновления камеры.
   */
  beforeDestroy() {
    clearTimeout(this.refreshTimeoutId);
    clearTimeout(this.timeoutIdForReceivingRecordingStatus);
  },
  methods: {
    async sendPTZRequest({ action, code, camera_number }) {
      const payload = {
        action,
        camera_number,
      };
      if (code) {
        payload.code = code;
      }
      await this.$store.dispatch(`cameras/${ACTION_PTZ_CAMERA}`, payload);
    },
    async startPTZ(direction) {
      await this.sendPTZRequest({ action: 'start', code: direction, camera_number: this.cameraInfo.number });
    },
    async stopPTZ() {
      clearInterval(this.ptzInterval);
      await this.sendPTZRequest({ action: 'stop', code: '', camera_number:this.cameraInfo.number });
    },
    async centralizePTZ() {
      await this.sendPTZRequest({ action: 'centralize', code: '', camera_number: this.cameraInfo.number});
    },
    /**
     * Установка камеры в текущую ячейку.
     * Установка таймера для отправки события в родительских компонент для обновление камеры (токена),
     * после чего произойдет изменение в передаваемом свойстве {@link initialCameraInfo}
     * и повторно установится таймер для уже нового экземпляра.
     *
     * Обновление камеры происходит через {@see loadCameraInfoForMultiMixin.methods.loadCameraInfo}, а там см. на использование кеша,
     * потому при расчете таймаута обновления используется {@see VUEX_CACHE_TTL}.
     *
     * @param {CameraInfo} cameraInfo
     */
    setupCamera(cameraInfo) {
      clearTimeout(this.refreshTimeoutId);
      this.cameraInfo = cameraInfo;
      if (!this.cameraInfo) {
        return;
      }
      if (this.cameraInfo.isReadyForLive) {
        this.refreshTimeoutId = setTimeout(() => {
          this.$emit("need-refresh", cameraInfo.number);
        }, whenToUpdateToken(TOKEN_TTL.MULTI, VUEX_CACHE_TTL));
      }
      if (this.showMediator) {
        this.receiveRecordingStatus();
      }
    },
    refreshCamera(cameraNumber) {
      this.$emit("need-refresh", cameraNumber);
    },
    /**
     * Открытие диалога выбора камеры.
     *
     * Регистрируется обработчик при закрытии диалога, в который передается номер выбранной камеры
     * для ее загрузки через родительский компонент.
     */
    openSelectCameraDialog() {
      this.$camsdals.open(
        MeshFrameDialog,
        {},
        {dialogTitle: "Выберите камеру для ячейки"},
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside",
          onClose: (cameraNumber) => {cameraNumber && this.$emit("new-camera", cameraNumber);}
        }
      );
    },
    /**
     * Удаление из текущего элемента сетки камеры (прекращается трансляция).
     * Передача события о новом состоянии элемента сетки в родительский компонент.
     */
    deleteCamera() {
      this.$emit("new-camera", null);
      this.cameraInfo = null;
      clearTimeout(this.refreshTimeoutId);
      clearTimeout(this.timeoutIdForReceivingRecordingStatus);
    },
    /**
     * Получение доступных фрагментов видео от сервера. Периодический ее вызов для актуализации данных в плеере.
     */
    async receiveRecordingStatus() {
      if (!this.cameraInfo?.isAvailableArchive()) {
        return;
      }

      this.availableArchiveFragments = await this.$store.dispatch(`cameras/${ACTION_LOAD_RECORDING_STATUSES}`, {
        cameraNumber: this.cameraInfo.number,
        domain: this.cameraInfo.server.domain,
        token: this.cameraInfo.tokenDVR,
      });
      this.timeoutIdForReceivingRecordingStatus = setTimeout(this.receiveRecordingStatus, 30000);
    },
  },
};
</script>
